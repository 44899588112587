@import '~antd/dist/reset.css';


.ant-popover .ant-popover-inner {
    padding: 0;
}
.ant-popover-title {
    padding: 10px 0 0 10px;
}
p {
    margin-top: 0;
    margin-bottom: 0;
}
::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.2);
}
::-webkit-scrollbar-track {
    border-radius: 0;
}
a {
    text-decoration: none;
}
