//把px转化成vw
@function pxToVW($px){
  @return $px/1920*100vw
}
//把px转化成vh
@function pxToVH($px){
  @return $px/1080*100vh
}

$primaryColor: #043156;

.flex {
  display: flex;
}
.flex-justify-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}

.header {
  width: 100%;
  height: 80px;
  background: $primaryColor;
  position: fixed;
  z-index: 999;
  p {
    margin: 0;
  }

  .header-layout {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 1200px;
    height: 100%;
  }

  
  .b-logo {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .left-nav {
    width: 50%;
    flex: 1;

    .name-logo {
      font-size: 24px;
      font-weight: 400;
      color: #fff;
      letter-spacing: .01em;
      text-decoration: none;
      display: flex;
      align-items: left;
      justify-content: left;
    }

  }

  .right-nav {
    width: 70%;
    display: inline-block;


    .logo {
      font-size: 18px;
      font-weight: 400;
      margin-left: 5%;
      color: #fff;
      letter-spacing: .01em;
      text-decoration: none;
    }

    .tasks {
      img {
        margin-right: 5px;
        margin-bottom: 4px;
      }
    }

    .demo {
      img {
        margin-right: 5px;
      }
    }

    .email-box {
      img {
        margin-right: 5px;
      }

      .send {
          color: #fff;
      }
    }

  }
  
  .login {
    font-size: 18px;
    color: white;
    letter-spacing: 0.1em;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  .b-user {
    display: flex;
    align-items: center;
    .b-user-photo {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background-color: #fff;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
      span{
        display: inline-block;
        text-align: center;
        line-height: 40px;
        width: 100%;
        font-weight: bold;
        color: $primaryColor;
      }
      .none{
        display: none !important;
      }
    }
    .b-user-info {
      display: flex;
      align-items: center;
      margin-left: 10px;
      color: #fff;
      p {
        margin-right: 5px;
      }
    }
  }
}
.header-user-center {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(8,33,38,0.1);
  border-radius: 10px;
    p {
      margin: 0;
    }
  .l-nav {
    position: relative;
    box-sizing: border-box;
    padding-left: 23px;
    display: flex;
    align-items: center;
    height: 60px;
    min-width: 206px;
    color: #666;
    border-bottom: 1px solid #F5F5F5;
    .l-nav-name {
      display: flex;
      align-items: center;
      height: 60px;
    }
    &:hover .l-nav-name {
      color: $primaryColor;
    }
    &:last-child {
      border-bottom: none;
    }
    .l-nav-name p {
      margin-left: 5px;
      font-size: 16px;
      font-weight: 400;
      height: 27px;
      cursor: pointer;
    }

    .b-sec-nav {
      display: none;
      position: absolute;
      top: 0;
      right: 100%;
      width: 248px;
      max-height: 282px;
      overflow: auto;
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(8,33,38,0.1);
      border-radius: 10px 0 10px 10px;
    }
    &:hover .b-sec-nav {
      display: block;
    }
    .l-sec-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      min-height: 74px;
      border-bottom: 1px solid #F5F5F5;
      box-sizing: border-box;
      padding: 10px 20px;
      cursor: pointer;
      &:last-child {
        border-bottom: 0;
      }
      &:hover {
        color: $primaryColor;
      }
      .i-choose {
        display: none;
      }
      &:hover .i-choose{
        display: block;
      }
      .icon-has {
        display: block;
      }
    }
    .hide{
      display: none;
    }
    .l-sec-btn {
      justify-content: left;
    }
   .p-name {
     line-height: 20px;
     font-size: 16px;
     font-weight: 400;
   }
    .p-date {
      margin-top: 10px;
     line-height: 12px;
     font-size: 12px;
     font-weight: 400;
   }
  }

}
