body {
  margin: 0;
  font-family: Lato !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1280px;
}

code {
  font-family: Lato !important;
}
