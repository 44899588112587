//把px转化成vw
@function pxToVW($px){
  @return $px/1920*100vw
}
//把px转化成vh
@function pxToVH($px){
  @return $px/1080*100vh
}

$primaryColor: #043156;

.flex {
  display: flex;
}
.flex-justify-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}

$base-font-color: #FFFFFF;
$forgot-font-color: #CCF5FF;
$input-background: #F8F8F8;
$button-background: #CCF5FF;
$button-hover-background: #1E768C;
$button-font-color: #1E768C;
$form-box-background-color: #3C9495;
$shadow-color: #A7A7A7;

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin button-style {
  display: block;
  width: 100%;
  padding: 0.75em 0;
  text-align: center;
  background: $button-background;
  border-radius: 27px;
  font-size: 1rem;
  color: $button-font-color;
  cursor: pointer;
  &:hover {
    background: $button-hover-background;
    color: $base-font-color;
  }
}

.p-login {
  @include flex-center;
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/bg-login.png") repeat center;
  background-size: cover;

  .form-box {
    @include flex-center;
    width: 85%;
    height: 75%;
    background-color: $form-box-background-color;
    box-shadow: 30px 30px 35px 0px #A7A7A7;
    .banner-box{
      width: 50%;
      padding-right: 5%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .form, .register-form {
      width: 120%;
      .welcome, .label, .form-input, .button, .forgot {
        margin-top: 1em;
      }

      .welcome {
        font-size: 2rem;
        color: $base-font-color;
      }

      .label {
        font-size: 1rem;
        color: $base-font-color;
      }

      .form-input {
        width: 100%;
        padding: 0.5em 1em;
        background: $input-background;
        border-radius: 24px;
        border: 1px solid $input-background;
        outline: none;
      }

      .button {
        @include button-style;
      }

      .forgot {
        font-size: 0.9rem;
        color: $forgot-font-color;
        cursor: pointer;
      }
    }
  }

  .none {
    display: none;
  }
}
