//把px转化成vw
@function pxToVW($px){
  @return $px/1920*100vw
}
//把px转化成vh
@function pxToVH($px){
  @return $px/1080*100vh
}

$primaryColor: #043156;

.flex {
  display: flex;
}
.flex-justify-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}

.layout {
  width: 100%;
  height: 100vh;
}
.view-content {
  box-sizing: border-box;
  padding: 0 20px 40px;
  .view_main{
    margin: 0 auto;
    width: 1200px;
  }
}
.nav-placeholder {
  height: 80px;
}
